import React from 'react'
import { IAppState } from '../../../context/context'
import { intl, locale } from '../../../i18n'
import ImageGallery from '../../imageGallery/ImageGallery'
import { PromotionBanner } from '../../promotionBanner/PromotionBanner'
import { connect } from 'react-redux'
import { getModeloImagenes, priceWithTax } from '../utils'
import { selectPostCode } from '../../../context/selectors/userSelectors'
import { MarcaEstaciones } from '../Labels/MarcaEstaciones'
import EcoLabels from '../../EcoLabels/EcoLabels'
import {
  ConsumoLevels,
  LLuviaLevels,
  RuidoLevels,
} from '../../../types/Ecolabel'
import { getImages, ImageNode } from '../../../utils/imagesS3'
import ResponsiveSliderBanner from '../../ResponsiveSliderBanner/ResponsiveSliderBanner'
import useUpdatePromocion from '../../../helpers/customHooks/useUpdatePromocion'
import styles from './card.module.scss'
import { CharacteristicsParagraphs } from '../Characteristics/CharacteristicsParagraphs'
import ComprarNeumaticoCTA from '../Ctas/ComprarNeumaticoCTA'
import {
  ModeloCocheInfo,
  NeumaticoCocheInfo,
  NeumaticoCocheProducto,
} from '../types'

interface NeumaticoMichelinCardProps {
  postCode: string | null
  modelo: ModeloCocheInfo
  neumatico: NeumaticoCocheInfo & NeumaticoCocheProducto
  banners: { [key: number]: ImageNode[] }
}
const NeumaticoMichelinCard: React.FC<NeumaticoMichelinCardProps> = ({
  postCode,
  modelo,
  neumatico,
  banners,
}) => {
  const updatedPromotion = useUpdatePromocion({
    id_site_marca_neumatico: modelo.id_site_marca_neumatico,
    id_site_modelo_neumatico: modelo.id_site_modelo_neumatico,
    llanta: neumatico.llanta,
    runflat: neumatico.runflat,
    allseason: neumatico.allseason,
    invierno: neumatico.invierno,
    postCode,
  })

  const bannerImagesDesktop = getImages(banners[`${locale}_desktop`], locale)
  const bannerImagesMobile = getImages(banners[`${locale}_mobile`], locale)

  const { recortes } = getModeloImagenes(modelo.imagenes)

  return (
    <div className={styles.neumatico_card__container}>
      <div className={styles.neumatico_card__left_sidebar}>
        <ImageGallery
          marca={modelo.marca.logo}
          fotos={recortes}
          tipo={'recorte'}
        />
      </div>
      <div className={styles.neumatico_card__content}>
        <div className={styles.neumatico_card__row}>
          <div
            className={`${styles.neumatico_card__left_column} ${styles.order_5}`}>
            <MarcaEstaciones
              verano={neumatico.verano}
              invierno={neumatico.invierno}
              allseason={neumatico.allseason}
              marca={modelo.marca.nombre}
            />
            <EcoLabels
              consumo={neumatico.consumo as ConsumoLevels}
              lluvia={neumatico.lluvia as LLuviaLevels}
              ruido={neumatico.nivel_ruido as RuidoLevels}
              decibelios={Number(neumatico.decibelios)}
              eprel={neumatico.eprel}
            />
            <div className={styles.neumatico_card__michelin_text}>
              <CharacteristicsParagraphs
                modelo={modelo}
                title={'landing_neumaticos.info.recomendado_para'}
                textKeys={['recomendado_para']}
              />
              <CharacteristicsParagraphs
                modelo={modelo}
                title={'landing_neumaticos.info.caracteristicas'}
                textKeys={[
                  'caracteristicas1',
                  'caracteristicas2',
                  'caracteristicas3',
                ]}
              />
            </div>
          </div>
          <div
            className={`${styles.neumatico_card__right_column} ${styles.order_2}`}>
            <div className={styles.neumatico_card__price}>
              {intl.formatNumber(priceWithTax(neumatico.producto), {
                style: 'currency',
                currency: 'EUR',
              })}
            </div>
            <ComprarNeumaticoCTA
              neumaticoInfo={neumatico}
              marcaInfo={modelo.marca}
            />
            {updatedPromotion.texto_promociones && (
              <div className={styles.neumatico_card__promobanner}>
                <PromotionBanner promocion={updatedPromotion} />
              </div>
            )}
          </div>
          {bannerImagesDesktop.length || bannerImagesMobile.length ? (
            <div
              className={`${styles.neumatico_card__full_column} ${styles.order_3}`}>
              <ResponsiveSliderBanner
                mobileImages={bannerImagesMobile}
                desktopImages={bannerImagesDesktop}
                sizesVariant={'banner_neumaticos'}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: IAppState) => {
  return {
    postCode: selectPostCode(state),
  }
}
export default connect(mapStateToProps)(NeumaticoMichelinCard)
