import { graphql, PageProps } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../components/Layout'
import { t, textWithLocale } from '../../i18n'
import route from '../../utils/route'
import { makeNeumaticosSchema } from '../../utils/schema'
import {
  getModeloImagenes,
  getModeloMarca,
  getNeumaticoSpecs,
  neumaticoActivo,
  priceWithTax,
} from '../../components/FichasNeumaticos/utils'
import NeumaticoMichelinCard from '../../components/FichasNeumaticos/Cards/NeumaticoMichelinCard'
import NeumaticoMichelinInactivoCard from '../../components/FichasNeumaticos/Cards/NeumaticoMichelinInactivoCard'
import { useSelectedMenu } from '../../../../sites/rodi/helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../../../sites/rodi/constants/menu'
import styles from './fichas.module.scss'

const LandingNeumaticosMichelinTunel = ({
  data: {
    modeloInfo,
    neumaticoInfo,
    seoData,
    modeloRecomendadoInfo,
    neumaticoRecomendadoInfo,
    banners_es_desktop,
    banners_ca_desktop,
    banners_es_mobile,
    banners_ca_mobile,
  },
}: PageProps<Queries.NeumaticoMichelinPageQuery>) => {
  useSelectedMenu(MenuPrincipal.NEUMATICOS)

  const {
    modelo,
    marca: { nombre: marca },
    imagenes,
  } = modeloInfo

  const banners = {
    ca_desktop: banners_ca_desktop.nodes,
    ca_mobile: banners_ca_mobile.nodes,
    es_desktop: banners_es_desktop.nodes,
    es_mobile: banners_es_mobile.nodes,
  }

  const { fichas } = getModeloImagenes(imagenes)

  const activo = neumaticoActivo(neumaticoInfo.producto)
  return (
    <Layout
      selector={false}
      seoData={seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.neumaticos'),
          link: route('neumaticos.index'),
        },
        {
          display: marca,
          link: route('neumaticos.marca', {
            marca: marca.toLowerCase(),
          }),
        },
        {
          display: modelo,
        },
      ]}>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            makeNeumaticosSchema({
              name: getModeloMarca(modeloInfo),
              image: fichas.length && fichas[0].url,
              description: modelo[textWithLocale('recomendado_para')],
              brand: marca,
              price: priceWithTax(neumaticoInfo.producto).toString(),
            })
          ),
        }}
      />
      <section className={styles.ficha_neumatico__container}>
        <section className={styles.ficha_neumatico__content}>
          <h1 className={styles.ficha_neumatico__michelin_title}>
            {getModeloMarca(modeloInfo)} {getNeumaticoSpecs(neumaticoInfo)}
          </h1>
          {activo ? (
            <NeumaticoMichelinCard
              modelo={modeloInfo}
              neumatico={neumaticoInfo}
              banners={banners}
            />
          ) : (
            <NeumaticoMichelinInactivoCard
              modelo={modeloInfo}
              neumatico={neumaticoInfo}
            />
          )}
        </section>
      </section>
      {!activo && neumaticoRecomendadoInfo && modeloRecomendadoInfo && (
        <React.Fragment>
          <section
            className={`${styles.ficha_neumatico__container_recomendado}`}>
            <h1
              className={styles.ficha_neumatico__container_recomendado__title}>
              {t('landing_neumaticos.info.te_recomendamos')}
            </h1>
          </section>
          <section className={styles.ficha_neumatico__container}>
            <section className={styles.ficha_neumatico__content}>
              <h1 className={styles.ficha_neumatico__michelin_title}>
                {getModeloMarca(modeloInfo)} {getNeumaticoSpecs(neumaticoInfo)}
              </h1>
              <NeumaticoMichelinCard
                neumatico={neumaticoRecomendadoInfo}
                modelo={modeloRecomendadoInfo}
                banners={banners}
              />
            </section>
          </section>
        </React.Fragment>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query NeumaticoMichelinPage(
    $idModelo: Int!
    $idSiteProductoNeumatico: Int!
    $banners_es_desktop: String!
    $banners_ca_desktop: String!
    $banners_es_mobile: String!
    $banners_ca_mobile: String!
    $url: String!
    $idModeloNeumaticoRecomendado: Int
    $idNeumaticoRecomendado: Int
  ) {
    modeloInfo: modelos(id_site_modelo_neumatico: { eq: $idModelo }) {
      ...modeloInfo
      ...modeloMarca
      ...modeloImagen
    }

    neumaticoInfo: neumaticos(
      id_site_productos_neumaticos: { eq: $idSiteProductoNeumatico }
    ) {
      ...neumaticoInfo
      ...productoInfo
    }

    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }

    modeloRecomendadoInfo: modelos(
      id_site_modelo_neumatico: { eq: $idModeloNeumaticoRecomendado }
    ) {
      ...modeloInfo
      ...modeloMarca
      ...modeloImagen
    }
    neumaticoRecomendadoInfo: neumaticos(
      id_site_productos_neumaticos: { eq: $idNeumaticoRecomendado }
    ) {
      ...neumaticoInfo
      ...productoInfo
    }

    banners_ca_desktop: allS3Asset(
      filter: { Key: { glob: $banners_ca_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_ca_mobile: allS3Asset(
      filter: { Key: { glob: $banners_ca_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_desktop: allS3Asset(
      filter: { Key: { glob: $banners_es_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_mobile: allS3Asset(
      filter: { Key: { glob: $banners_es_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
  }
`

export default LandingNeumaticosMichelinTunel
