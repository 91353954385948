import React from 'react'
import { t } from '../../../i18n'
import styles from './marcaEstaciones.module.scss'

interface Props {
  allseason: number
  invierno: number
  verano: number
  marca: string
}
export const MarcaEstaciones = ({
  allseason,
  invierno,
  verano,
  marca,
}: Props) => (
  <p className={styles.marca_estacion_label}>
    {marca}
    {allseason || verano || invierno ? ' - ' : ''}
    {allseason === 1 ? t('ficha-marca.todo-el-año') : null}
    {verano === 1 ? t('ficha-marca.verano') : null}
    {invierno === 1 ? t('ficha-marca.invierno') : null}
  </p>
)
