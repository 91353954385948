import React, { useState } from 'react'
import { t, textWithLocale } from '../../../i18n'
import styles from './card.module.scss'
import ImageGallery from '../../imageGallery/ImageGallery'
import { RequestTyreData } from '../../../types/Neumatico'
import { MarcaEstaciones } from '../Labels/MarcaEstaciones'
import EcoLabels from '../../EcoLabels/EcoLabels'
import Email from '../../../assets/email.svg'
import StandardModal from '../../modal/standardModal/StandardModal'
import RequestTyre from '../../RequestTyre/RequestTyre'
import { getModeloImagenes } from '../utils'
import {
  ConsumoLevels,
  LLuviaLevels,
  RuidoLevels,
} from '../../../types/Ecolabel'
import {
  ModeloCocheInfo,
  NeumaticoCocheInfo,
  NeumaticoCocheProducto,
} from '../types'

interface NeumaticoMichelinInactivoCardProps {
  modelo: ModeloCocheInfo
  neumatico: NeumaticoCocheInfo & NeumaticoCocheProducto
}
const NeumaticoMichelinInactivoCard: React.FC<
  NeumaticoMichelinInactivoCardProps
> = ({ modelo, neumatico }) => {
  const [showModal, setShowModal] = useState(false)
  const closeModal = () => {
    setShowModal(false)
  }
  const [successMessage, setSuccessMessage] = useState(false)

  const selectedNeumatico: RequestTyreData = {
    marca: modelo.marca.nombre,
    modelo: modelo.modelo,
    id_site_modelo_neumatico: modelo.id_site_modelo_neumatico,
    id_site_producto: neumatico.id_site_productos_neumaticos,
    tyreSpecs: {
      ancho: neumatico.ancho,
      cv: neumatico.cv,
      ic: neumatico.ic,
      llanta: neumatico.llanta,
      serie: neumatico.serie,
    },
    descripcion: neumatico.producto[textWithLocale('descripcion')],
  }
  const { recortes } = getModeloImagenes(modelo.imagenes)
  return (
    <React.Fragment>
      <div className={styles.neumatico_card__container}>
        <div className={styles.neumatico_card__left_sidebar}>
          <ImageGallery
            marca={modelo.marca.logo}
            fotos={recortes}
            tipo={'recorte'}
          />
        </div>
        <div className={styles.neumatico_card__content}>
          <div className={styles.neumatico_card__row}>
            <div
              className={`${styles.neumatico_card__left_column} ${styles.order_5}`}>
              <MarcaEstaciones
                verano={neumatico.verano}
                invierno={neumatico.invierno}
                allseason={neumatico.allseason}
                marca={modelo.marca.nombre}
              />
              <EcoLabels
                consumo={neumatico.consumo as ConsumoLevels}
                lluvia={neumatico.lluvia as LLuviaLevels}
                ruido={neumatico.nivel_ruido as RuidoLevels}
                decibelios={Number(neumatico.decibelios)}
                eprel={neumatico.eprel}
              />
              <h2 className={styles.neumatico_card__no_disponible}>
                {t('landing_neumaticos.info.no_disponible')}
              </h2>
              <a
                className={styles.neumatico_card__disponibilidad_claim}
                onClick={() => setShowModal(true)}>
                <Email className={styles.email_icon} />
                {t('landing_neumaticos.info.aviso_disponibilidad')}
              </a>
            </div>
          </div>
        </div>
      </div>
      <StandardModal
        title={t('landing_neumaticos.neumatico_item.disponibilidad')}
        closeModal={closeModal}
        showModal={showModal}>
        <RequestTyre
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          selectedNeumatico={selectedNeumatico}
        />
      </StandardModal>
    </React.Fragment>
  )
}

export default NeumaticoMichelinInactivoCard
